.has-customer {
  background-repeat: no-repeat;
  background-position: fixed;
  background-attachment: fixed;
  background-size: cover;
}

@media (min-width: 601px) {
  .has-customer {
    background-image: url('../../images/background-desktop3.jpg');
  }
}

@media (max-width: 600px) {
  .has-customer {
    background-image: url('../../images/background-mobile3.jpg');
  }
}
