html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  background-color: rgb(247, 250, 252);
}

#root {
  height: 100%;
}

/* Disabling Text Selection and Highlight (EX-622) */
:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input,
button,
textarea,
:focus {
  outline: none;
}

/* Disable dragging of links (EX-622) */
a:not([draggable='true']),
img:not([draggable='true']) {
  -webkit-user-drag: none;
  user-drag: none;
}
a[href^="http://"],
a[href^="https://"],
a[href^="ftp://"]
{
  -webkit-user-drag: auto;
  user-drag: auto;
}

/* Autofill color to grey */
select:-webkit-autofill,
select:-webkit-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 60px #fff9d8 inset !important;
  -moz-box-shadow: 0 0 0 60px #fff9d8 inset !important;
  box-shadow: 0 0 0 60px #fff9d8 inset !important;
}

@font-face {
  font-family: 'text-security-disc';
  src: url('./fonts/text-security-disc.eot');
  src: url('./fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
    url('./fonts/text-security-disc.woff') format('woff'),
    url('./fonts/text-security-disc.ttf') format('truetype'),
    url('./fonts/text-security-disc.svg#text-security') format('svg');
}

#single-spa-application\:\@solarmosaic-mfe\/header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

#root.p3xGlobalNavEnabled {
  padding-top: 40px;
}